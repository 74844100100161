const menuLink = {
  fontSize: "md",
  fontWeight: 500,
  mb: [2, 3, 4],
  letterSpacing: "-0.3px",
};
const section = {
  color: "black",
  fontSize: "lg",
  fontWeight: 500,
  mb: [2, 3, 4],
};

const level1 = {
  color: "black",
  fontSize: "2xl",
  fontWeight: 600,
  letterSpacing: "-0.3px",
  mb: 6,
};

const level3 = {
  color: "black",
  fontSize: "md",
  fontWeight: 600,
  mb: 0,
};

const level4 = {
  color: "black",
  fontSize: "sm",
  fontWeight: 600,
  mb: 0,
  letterSpacing: "-0.5px",
};

const Heading = {
  variants: {
    menuLink,
    section,
    level1,
    level3,
    level4,
  },
};

export default Heading;
