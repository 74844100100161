import { EUserRole } from "lib/types/auth";
import { IApiConsultantInfoResponse } from "lib/types/consultant";

export const consultants: IApiConsultantInfoResponse[] = [
  {
    id: 123,
    first_name: "Nanine",
    last_name: "Eccott",
    email: "neccott0@geocities.jp",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "pending",
  },
  {
    id: 234,
    first_name: "Wilfrid",
    last_name: "Papez",
    email: "wpapez1@go.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 345,
    first_name: "Sven",
    last_name: "Hearon",
    email: "shearon2@ehow.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "active",
  },
  {
    id: 456,
    first_name: "Urbano",
    last_name: "Colliver",
    email: "ucolliver3@abc.net.au",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "active",
  },
  {
    id: 567,
    first_name: "Bil",
    last_name: "Shitliffe",
    email: "bshitliffe4@wikimedia.org",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "pending",
  },
  {
    id: 678,
    first_name: "Wake",
    last_name: "Sizland",
    email: "wsizland5@sun.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "pending",
  },
  {
    id: 789,
    first_name: "Natal",
    last_name: "Pawson",
    email: "npawson6@zdnet.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.compliance,
    tags: [],
    status: "pending",
  },
  {
    id: 890,
    first_name: "Keelby",
    last_name: "Laughrey",
    email: "klaughrey7@cbc.ca",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "pending",
  },
  {
    id: 111,
    first_name: "Star",
    last_name: "Conerding",
    email: "sconerding8@yolasite.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 222,
    first_name: "Silvana",
    last_name: "Buyers",
    email: "sbuyers9@cisco.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "do not use",
  },
  {
    id: 333,
    first_name: "Gaye",
    last_name: "Taleworth",
    email: "gtalewortha@blogtalkradio.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "do not use",
  },
  {
    id: 444,
    first_name: "Ulrikaumeko",
    last_name: "Dundin",
    email: "udundinb@pagesperso-orange.fr",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "do not use",
  },
  {
    id: 555,
    first_name: "Camile",
    last_name: "Mitchelson",
    email: "cmitchelsonc@hc360.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.consultant,
    tags: [],
    status: "do not use",
  },
  {
    id: 666,
    first_name: "Thelma",
    last_name: "Manifold",
    email: "tmanifoldd@vimeo.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.consultant,
    tags: [],
    status: "active",
  },
  {
    id: 777,
    first_name: "Rupert",
    last_name: "Pywell",
    email: "rpywelle@newsvine.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.consultant,
    tags: [],
    status: "active",
  },
  {
    id: 888,
    first_name: "Alfi",
    last_name: "Scaife",
    email: "ascaifef@un.org",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 999,
    first_name: "Corabel",
    last_name: "Jacobi",
    email: "cjacobig@opera.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 110,
    first_name: "Izzy",
    last_name: "Gibberd",
    email: "igibberdh@youtube.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 220,
    first_name: "Hilde",
    last_name: "Aiton",
    email: "haitoni@linkedin.com",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
  {
    id: 330,
    first_name: "Jaye",
    last_name: "Burde",
    email: "jburdej@si.edu",
    created_at: new Date(2021, 0, 1).toISOString(),
    user_type: EUserRole.admin,
    tags: [],
    status: "active",
  },
];
