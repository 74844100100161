import { EUserRole } from "lib/types/auth";
import { IApiClientInfoResponse, TApiClientParent } from "lib/types/client";

export const clientParents: TApiClientParent[] = [
  { client_name: "NE6", id: 123 },
  { client_name: "Starbucks", id: 456 },
];

export const clientChildren: IApiClientInfoResponse[] = [
  {
    id: 1000,
    client_name: "CLient Name",
    service_name: "Service Name",
    service_location: "Service location",
    manager_first_name: "MFName",
    manager_last_name: "MLName",
    manager_email: "memail@example.com",
    telephone: "10661 830909",
    additional_contact_first_name: "ACFName",
    additional_contact_last_name: "ACLName",
    additional_contact_email: "acemail@example.com",
    status: "active",
    user_type: EUserRole.client,
    updated_at: "2021-09-09T15:00:17.000000Z",
    created_at: "2021-09-09T15:00:17.000000Z",
    children: [],
    is_child: true,
    parent: 123,
    parent_name: "NE6",
    use_parent_rate_card: 0,
    file_one: {
      name: "privacy document",
      original_name: "test name.doc",
      url: "test url",
    },
    disable_email: 1,
    tags: [],
  },
];

export const clients: IApiClientInfoResponse[] = [
  {
    id: 123,
    client_name: "Linkbuzz",
    service_name: "Care Home",
    service_location: "Parkside Carehome",
    manager_first_name: "Luther",
    manager_last_name: "Smith",
    manager_email: "lsmith@example.com",
    telephone: "10661 830909",
    additional_contact_first_name: "Andrea",
    additional_contact_last_name: "Button",
    additional_contact_email: "abutton@example.com",
    status: "active",
    user_type: EUserRole.client,
    updated_at: "2021-09-09T15:00:17.000000Z",
    created_at: "2021-09-09T15:00:17.000000Z",
    children: [],
    is_child: false,
    parent: null,
    parent_name: null,
    use_parent_rate_card: 0,
    file_one: {
      name: "privacy document",
      original_name: "test name.doc",
      url: "test url",
    },
    disable_email: 1,
    tags: [],
  },
];

//     contact_name: "Luther Smith",
//     user_type: EUserRole.client,
//     company_name: "Linkbuzz",
//     company_number: "85",
//     email: "laldersea0@npr.org",
//     work_number: "527-630-9589",
//     mobile_number: "143-323-7531",
//     location: "Kayan Hulu",
//     status: "do not use",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 112,
//     contact_name: "Leontine Smith",
//     user_type: EUserRole.client,
//     company_name: "Jaxbean",
//     company_number: "4",
//     email: "lgantzer1@seattletimes.com",
//     work_number: "527-558-4829",
//     mobile_number: "705-810-0134",
//     location: "Corumbá",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 113,
//     contact_name: "Tybie Smith",
//     user_type: EUserRole.client,
//     company_name: "Tavu",
//     company_number: "41",
//     email: "trentz2@mayoclinic.com",
//     work_number: "942-891-6921",
//     mobile_number: "444-436-4294",
//     location: "Rumburk",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 114,
//     contact_name: "Gabie Smith",
//     user_type: EUserRole.client,
//     company_name: "Aimbo",
//     company_number: "18745",
//     email: "gelsmore3@imageshack.us",
//     work_number: "683-322-6392",
//     mobile_number: "609-172-1979",
//     location: "Bungkulan",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 115,
//     contact_name: "Cortney Smith",
//     user_type: EUserRole.client,
//     company_name: "Jabbercube",
//     company_number: "956",
//     email: "ccage4@histats.com",
//     work_number: "631-429-2889",
//     mobile_number: "113-505-0034",
//     location: "Buan",
//     status: "do not use",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 116,
//     contact_name: "Gard Smith",
//     user_type: EUserRole.client,
//     company_name: "Cogidoo",
//     company_number: "05503",
//     email: "gitchingham5@cargocollective.com",
//     work_number: "397-879-4419",
//     mobile_number: "385-653-1421",
//     location: "Sumberan",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 117,
//     contact_name: "Ellis Smith",
//     user_type: EUserRole.client,
//     company_name: "Agivu",
//     company_number: "19528",
//     email: "ejahnke6@icio.us",
//     work_number: "260-702-3011",
//     mobile_number: "585-181-0023",
//     location: "San Agustin",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 118,
//     contact_name: "Omero Smith",
//     user_type: EUserRole.client,
//     company_name: "Trunyx",
//     company_number: "9",
//     email: "oebrall7@ucoz.ru",
//     work_number: "130-206-1427",
//     mobile_number: "558-706-1381",
//     location: "Sukamaju",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 119,
//     contact_name: "Danica Smith",
//     user_type: EUserRole.client,
//     company_name: "Eare",
//     company_number: "88",
//     email: "dmowsdill8@i2i.jp",
//     work_number: "704-887-0117",
//     mobile_number: "740-739-9036",
//     location: "Charlotte",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 120,
//     contact_name: "Dominga Smith",
//     user_type: EUserRole.client,
//     company_name: "Blogspan",
//     company_number: "7",
//     email: "dscotter9@technorati.com",
//     work_number: "981-546-3228",
//     mobile_number: "148-183-5595",
//     location: "Qukës-Skënderbe",
//     status: "active",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 121,
//     contact_name: "Arlinda Smith",
//     user_type: EUserRole.client,
//     company_name: "Ntag",
//     company_number: "4",
//     email: "achenga@newyorker.com",
//     work_number: "372-660-6396",
//     mobile_number: "831-728-5815",
//     location: "Galūgāh",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 122,
//     contact_name: "Bernarr Smith",
//     user_type: EUserRole.client,
//     company_name: "Voonyx",
//     company_number: "7",
//     email: "bdreppb@cbsnews.com",
//     work_number: "911-534-5520",
//     mobile_number: "203-488-6432",
//     location: "Tawau",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 123,
//     contact_name: "Fanchon Smith",
//     user_type: EUserRole.client,
//     company_name: "Meejo",
//     company_number: "329",
//     email: "ffruinc@meetup.com",
//     work_number: "705-943-6611",
//     mobile_number: "473-102-0952",
//     location: "Valenciennes",
//     status: "active",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 124,
//     contact_name: "Neill Smith",
//     user_type: EUserRole.client,
//     company_name: "Buzzster",
//     company_number: "79059",
//     email: "nkitchingmand@dell.com",
//     work_number: "759-778-2815",
//     mobile_number: "312-866-6618",
//     location: "Chimoio",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 125,
//     contact_name: "Alfonso Smith",
//     user_type: EUserRole.client,
//     company_name: "Pixope",
//     company_number: "14",
//     email: "ahollyare@amazon.com",
//     work_number: "517-809-5999",
//     mobile_number: "712-622-3658",
//     location: "Ochër",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 126,
//     contact_name: "Marthena Smith",
//     user_type: EUserRole.client,
//     company_name: "Voonte",
//     company_number: "32123",
//     email: "msaffrinf@chicagotribune.com",
//     work_number: "184-216-7642",
//     mobile_number: "634-274-2074",
//     location: "Chirongui",
//     status: "active",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 127,
//     contact_name: "Hogan Smith",
//     user_type: EUserRole.client,
//     company_name: "Vidoo",
//     company_number: "245",
//     email: "hnardig@ucla.edu",
//     work_number: "893-252-5231",
//     mobile_number: "863-510-6339",
//     location: "Kanash",
//     status: "pending",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 128,
//     contact_name: "Melisande Smith",
//     user_type: EUserRole.client,
//     company_name: "Camimbo",
//     company_number: "78",
//     email: "mmarfellh@histats.com",
//     work_number: "484-102-0240",
//     mobile_number: "682-968-9102",
//     location: "Klenovyy",
//     status: "pre-reg",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 129,
//     contact_name: "Marge Smith",
//     user_type: EUserRole.client,
//     company_name: "Vidoo",
//     company_number: "5",
//     email: "mpracyi@senate.gov",
//     work_number: "912-283-8482",
//     mobile_number: "764-562-7432",
//     location: "Savannah",
//     status: "active",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
//   {
//     id: 130,
//     contact_name: "Garreth Smith",
//     user_type: EUserRole.client,
//     company_name: "Quimba",
//     company_number: "485",
//     email: "gtanslyj@wikimedia.org",
//     work_number: "348-648-7640",
//     mobile_number: "795-449-0950",
//     location: "Kruisfontein",
//     status: "active",
//     created_at: new Date(2021, 0, 1).toISOString(),
//     file: {
//       name: "Test file.doc",
//       url: "http://dummyimage.com/139x100.png/dddddd/000000",
//     },
//   },
// ];
