const Accordion = {
  defaultProps: {
    variant: "standby",
  },
  baseStyle: {
    button: {
      pl: 0,
    },
    panel: {
      px: 0,
    },
  },
  variants: {
    standby: {
      root: {
        px: 0,
      },
      container: {},
      button: {
        bg: "primary.500",
        color: "white",
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: 1,
        borderTopLeftRadius: "21px",
        borderTopRightRadius: "21px",
        borderBottomLeftRadius: "21px",
        borderBottomRightRadius: "21px",
        transition: "all 0.2s",
        h: "42px",
        pl: 5,
        pr: 5,
        py: 3,
        borderBottom: 0,
        borderTop: 0,
        _hover: {
          bg: "primary.200",
        },
        _expanded: {
          bg: "primary.100",
          color: "primary.600",
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
        },
      },
      panel: {
        mb: 2.5,
        py: 5,
        px: 5,
        border: "1px solid",
        borderColor: "primary.500",
        borderTop: 0,
        borderBottomLeftRadius: "21px",
        borderBottomRightRadius: "21px",
      },
      icon: {},
    },
  },
};

export default Accordion;
