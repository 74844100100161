import { eachDayOfInterval, format, parse } from "date-fns";
import { rest } from "msw";

import { shifts } from "../data/shifts";
import type { IScheduleShiftsByDay } from "lib/types/schedule";
import { AcceptDeclineResponse } from "components/RosterNew/Schedule";

const generateShifts = (start: string, end: string) => {
  const dataRange = eachDayOfInterval({
    start: parse(start, "yyyy-MM-dd", new Date()),
    end: parse(end, "yyyy-MM-dd", new Date()),
  });

  const shiftsByDay: IScheduleShiftsByDay = dataRange.reduce(
    (byDay, date, idx) => {
      return {
        ...byDay,
        [format(date, "yyyy-MM-dd")]: [shifts[idx]],
      };
    },
    {} as IScheduleShiftsByDay
  );
  return shiftsByDay;
};

const createShift = rest.post("/shifts/create", async (req: any, res, ctx) => {
  return res(ctx.status(200), ctx.json({}));
});

const getClientShiftsAll = rest.post(
  "/clients/:id/shift_schedule",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }
);
const geStaffShiftsAll = rest.post(
  "/staff/:id/shift_schedule",
  async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }
);

const getDashboardShifts = rest.post(
  "/dashboard/shift_schedule",
  async (req: any, res, ctx) => {
    const { start_date, end_date } = req.body;
    return res(ctx.status(200), ctx.json(generateShifts(start_date, end_date)));
  }
);

const acceptShift = rest.post(
  "/shifts/accept_shift",
  async (_: any, res, ctx) => {
    const response: AcceptDeclineResponse = {
      message: "Shift accepted",
      success: true,
    };
    return res(ctx.status(200), ctx.json(response));
  }
);
const declineShift = rest.post(
  "/shifts/decline_shift",
  async (_: any, res, ctx) => {
    const response: AcceptDeclineResponse = {
      message: "Shift declined",
      success: true,
    };
    return res(ctx.status(200), ctx.json(response));
  }
);
const approveTimesheetShift = rest.post(
  "/shifts/approval/:shift_id",
  async (_: any, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ success: true, message: "Shift approved" })
    );
  }
);

const deleteShift = rest.post(
  "/shifts/delete/:shiftId",
  async (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
      })
    );
  }
);
export const shiftHandlers = [
  createShift,
  getClientShiftsAll,
  geStaffShiftsAll,
  getDashboardShifts,
  acceptShift,
  declineShift,
  approveTimesheetShift,
  deleteShift,
];
