import { rest } from "msw";

import { notifications } from "../data/notifications";

const fetchNotifications = rest.get(
  "/notifications",
  async (req: any, res, ctx) => {
    const authHeader = req?.headers?._headers?.authorization;

    if (authHeader.includes("admin")) {
      return res(
        ctx.status(200),
        ctx.json({ data: notifications.slice(0, 5) })
      );
    }

    if (authHeader.includes("staff")) {
      return res(ctx.status(200), ctx.json({ data: [] }));
    }

    return res(
      ctx.status(400),
      ctx.json({
        error: "Unable to fetch notifications",
      })
    );
  }
);

const fetchUserNotifications = rest.get(
  "/users/:id/notifications",
  async (req: any, res, ctx) => {
    const authHeader = req?.headers?._headers?.authorization;

    if (authHeader.includes("admin")) {
      return res(
        ctx.status(200),
        ctx.json({ data: notifications.slice(0, 5) })
      );
    }

    if (authHeader.includes("staff")) {
      return res(ctx.status(200), ctx.json({ data: [] }));
    }

    return res(
      ctx.status(400),
      ctx.json({
        error: "Unable to fetch notifications",
      })
    );
  }
);

export const notificationHandlers = [
  fetchNotifications,
  fetchUserNotifications,
];
