const label = {
  color: "black",
  fontSize: "xs",
  fontWeight: 600,
  mb: 0,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.83,
  letterSpacing: "-0.2px",
};

const body = {
  color: "darkgray",
  fontSize: "sm",
  fontWeight: 500,
  mb: 0,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.57,
  letterSpacing: "-0.5px",
};

const Text = {
  variants: {
    label,
    body,
  },
};

export default Text;
