import { INote } from "lib/types/note";

export const notes: INote[] = [
  {
    id: 111,
    note: "Phasellus in felis. Donec semper sapien a libero. Nam dui. Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis.",
    updated_at: "2020-11-30T13:16:29Z",
    marked_as: "Email Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 112,
    note: "Maecenas pulvinar lobortis est. Phasellus sit amet erat. Nulla tempus. Vivamus in felis eu sapien cursus vestibulum. Proin eu mi. Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem. Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy.",
    updated_at: "2020-08-24T00:09:23Z",
    marked_as: "Email Received",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 113,
    note: "Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit. Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi.",
    updated_at: "2020-11-14T07:24:48Z",
    marked_as: "Email Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 114,
    note: "Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem. Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo.",
    updated_at: "2020-09-11T15:52:09Z",
    marked_as: "Text Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 115,
    note: "Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst. Maecenas ut massa quis augue luctus tincidunt. Nulla mollis molestie lorem. Quisque ut erat. Curabitur gravida nisi at nibh.",
    updated_at: "2021-02-06T07:03:35Z",
    marked_as: "Text Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 116,
    note: "Morbi quis tortor id nulla ultrices aliquet. Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui. Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc.",
    updated_at: "2020-07-15T10:19:41Z",
    marked_as: "Reference Requested",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 117,
    note: "Integer a nibh. In quis justo. Maecenas rhoncus aliquam lacus. Morbi quis tortor id nulla ultrices aliquet. Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui. Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc.",
    updated_at: "2021-04-01T01:49:54Z",
    marked_as: "Reference Chased",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 118,
    note: "Sed ante. Vivamus tortor. Duis mattis egestas metus. Aenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh. Quisque id justo sit amet sapien dignissim vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est. Donec odio justo, sollicitudin ut, suscipit a, feugiat et, eros. Vestibulum ac est lacinia nisi venenatis tristique.",
    updated_at: "2021-02-22T05:02:23Z",
    marked_as: "DBS Link Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 119,
    note: "Nulla nisl. Nunc nisl. Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum. In hac habitasse platea dictumst.",
    updated_at: "2021-01-12T13:42:37Z",
    marked_as: "Email Received",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 211,
    note: "Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem. Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio. Cras mi pede, malesuada in, imperdiet et, commodo vulputate, justo.",
    updated_at: "2020-05-29T23:09:26Z",
    marked_as: "Training Link Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 212,
    note: "Morbi porttitor lorem id ligula. Suspendisse ornare consequat lectus. In est risus, auctor sed, tristique in, tempus sit amet, sem. Fusce consequat. Nulla nisl. Nunc nisl. Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum.",
    updated_at: "2021-04-07T01:09:45Z",
    marked_as: "Reference Chased",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 213,
    note: "Maecenas ut massa quis augue luctus tincidunt. Nulla mollis molestie lorem. Quisque ut erat. Curabitur gravida nisi at nibh. In hac habitasse platea dictumst. Aliquam augue quam, sollicitudin vitae, consectetuer eget, rutrum at, lorem. Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum sed magna at nunc commodo placerat.",
    updated_at: "2020-07-21T21:38:59Z",
    marked_as: "Email Received",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 214,
    note: "Suspendisse ornare consequat lectus. In est risus, auctor sed, tristique in, tempus sit amet, sem. Fusce consequat. Nulla nisl. Nunc nisl. Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa.",
    updated_at: "2020-07-08T14:13:26Z",
    marked_as: "Reference Requested",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 215,
    note: "Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem. Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit.",
    updated_at: "2020-09-29T08:07:26Z",
    marked_as: "Email Received",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 216,
    note: "Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat. In congue. Etiam justo. Etiam pretium iaculis justo. In hac habitasse platea dictumst. Etiam faucibus cursus urna. Ut tellus. Nulla ut erat id mauris vulputate elementum. Nullam varius.",
    updated_at: "2020-07-18T09:09:38Z",
    marked_as: "Reference Requested",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 217,
    note: "In est risus, auctor sed, tristique in, tempus sit amet, sem. Fusce consequat. Nulla nisl. Nunc nisl. Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum. In hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.",
    updated_at: "2020-07-26T05:36:03Z",
    marked_as: "Text Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 218,
    note: "Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl. Aenean lectus. Pellentesque eget nunc. Donec quis orci eget orci vehicula condimentum.",
    updated_at: "2020-11-08T02:15:37Z",
    marked_as: "Phone Call Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 219,
    note: "Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit. Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque. Duis bibendum. Morbi non quam nec dui luctus rutrum.",
    updated_at: "2021-02-27T09:32:30Z",
    marked_as: "Text Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 311,
    note: "Praesent blandit. Nam nulla. Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede. Morbi porttitor lorem id ligula. Suspendisse ornare consequat lectus. In est risus, auctor sed, tristique in, tempus sit amet, sem.",
    updated_at: "2021-02-08T09:53:03Z",
    marked_as: "Training Link Sent",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
  {
    id: 312,
    note: "Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem. Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit. Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque. Duis bibendum.",
    updated_at: "2020-09-07T04:08:27Z",
    marked_as: "Reference Chased",
    created_at: new Date(2018, 11, 25).toISOString(),
    created_by: 111,
    user_id: 111,
    file: false,
    pinned: false,
    pinned_at: null,
    pinned_by: null,
    can_pin: false,
    can_unpin: false,
    user: {
      first_name: "Test",
      last_name: "Test",
    },
    creator: {
      first_name: "Test",
      last_name: "Test",
    },
  },
];
