const borderRadius = "3px";
const borderColor = "darkgray";

const FormLabel = {
  baseStyle: {
    color: "#666",
    fontSize: "xs",
    fontWeight: 600,
    lineHeight: "normal",
  },
};

const Input = {
  baseStyle: {
    lineHeight: "normal",
    field: {
      minH: "48px",
      "&&": { borderColor, borderRadius },
      _readOnly: {
        bg: "gray.100",
        color: "#444",
        cursor: "not-allowed",
        _hover: {
          borderColor: "black",
        },
        _active: {
          borderColor: "black",
        },
      },
    },
  },
};

const NumberInput = {
  baseStyle: {
    lineHeight: "normal",
    field: {
      minH: "48px",
      "&&": { borderColor, borderRadius },
    },
  },
};

const Textarea = {
  baseStyle: {
    lineHeight: "normal",

    "&&": { borderColor, borderRadius, minH: "120px" },
    _readOnly: {
      bg: "gray.100",
      color: "#444",
      cursor: "not-allowed",
      _hover: {
        borderColor: "black",
      },
      _active: {
        borderColor: "black",
      },
    },
  },
};

const Select = {
  baseStyle: {
    fontSize: "md",
    field: {
      "&&": { borderColor, borderRadius },
    },
  },
  defaultProps: {
    size: "lg",
  },
};

const Radio = {
  baseStyle: {
    control: {
      h: 5,
      minH: 5,
      w: 5,
      minW: 5,
      borderColor,
      _hover: {
        bg: "primary.50",
      },
      _checked: {
        bg: "white",
        borderColor: "primary.500",
        _before: {
          bg: "primary.500",
        },
        _hover: {
          bg: "primary.50",
        },
      },
    },
    label: {
      my: 1,
      mr: 4,
      fontWeight: 500,
      lineHeight: "normal",
      pt: 1,
      color: "darkgray",
    },
  },
  variants: {
    red: {
      control: {
        _hover: {
          bg: "bgRed",
        },
        _checked: {
          bg: "white",
          borderColor: "availabilityRed",
          _before: {
            bg: "availabilityRed",
          },
          _hover: {
            bg: "bgRed",
          },
        },
      },
    },
    green: {
      control: {
        _hover: {
          bg: "bgGreen",
        },
        _checked: {
          bg: "white",
          borderColor: "availabilityGreen",
          _before: {
            bg: "availabilityGreen",
          },
          _hover: {
            bg: "bgGreen",
          },
        },
      },
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: "sm",
      },
      control: {
        p: "1px",
        borderWidth: "1px",
      },
    },
  },
};

const Form = {
  FormLabel,
  Input,
  NumberInput,
  Textarea,
  Select,
  Radio,
};
export default Form;
