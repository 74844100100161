const Tabs = {
  baseStyle: {
    tablist: {
      maxW: "100vw",
      overflow: "auto",
      pb: "2px",
    },
    tab: {
      color: "midgray",
      fontFamily: "Spartan",
      fontWeight: 600,
      letterSpacing: " -0.5px",
      borderBottom: "4px solid transparent",
      "&&": {
        pt: 6,
        pb: 4,
        textDecoration: "none",
        fontSize: "sm",
        _selected: {
          color: "#392E5A",
          borderBottomColor: "#392E5A",
          borderBottomWidth: "3px",
        },
      },
    },
  },
};

export default Tabs;
