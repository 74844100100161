import { IApiRateCardResponse, IRateCardForApproval } from "lib/types/rate";

export const rateCardForApproval: IRateCardForApproval = {
  id: 47,
  client_id: 16,
  title: "NE6 2021 Rates",
  first_name: "Ryan",
  last_name: "James",
  email: "ryan.james@ne6.studio",
  job_title: "CEO",
  agreed: 0,
  created_at: "2021-10-13T11:23:07.000000Z",
  updated_at: "2021-10-13T11:23:07.000000Z",
  approval_override: false,
  file_one: {
    url: "https://standby-api-development.jdsx.app/storage/clients/16//oZlGKNZcFPiGorWlViEBzjBD8zxgCWLoEb8xCb35.txt",
    name: "test-file.txt",
    original_name: "test-file.txt",
  },
  terms: "<p>these are the terms</p>",
  rates: [
    {
      id: 87,
      rate_card_id: 47,
      time_start: "08:00",
      time_end: "20:00",
      job_title: "Registered Nurse",
      shift_code: "MON – FRI (20:00-08:00)",
      pay_rate: "12",
      charge_rate: "24",
      created_at: "2021-10-13T11:23:07.000000Z",
      updated_at: "2021-10-13T11:23:07.000000Z",
    },
    {
      id: 88,
      rate_card_id: 47,
      time_start: "08:00",
      time_end: "20:00",
      job_title: "HCA",
      shift_code: "MON – FRI (08:00-20:00)",
      pay_rate: "13",
      charge_rate: "25",
      created_at: "2021-10-13T11:23:07.000000Z",
      updated_at: "2021-10-13T11:23:07.000000Z",
    },
  ],
  client: {
    id: 16,
    use_parent_rate_card: 0,
    status: "active",
    client_name: "NE6",
    service_name: "NE6 Care",
    service_location: "Newcastle",
    manager_first_name: "Steven",
    manager_last_name: "Symonds",
    manager_email: "ryan.james@ne6.studio",
    telephone: "01231231235",

    created_at: "2021-09-03T14:12:31.000000Z",
    updated_at: "2021-10-12T09:28:01.000000Z",
    is_child: false,
    children: [],
  },
};

export const rateCards: IApiRateCardResponse[] = [
  {
    id: 1,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    client_id: 2,
    approval_override: false,
    rates: [
      {
        id: 4,
        rate_card_id: 5,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Support Worker",
        pay_rate: "12.00",
        charge_rate: "23.00",
        shift_code: "BANK HOL (20:00-08:00)",
      },
    ],
    agreed: 1,
    created_at: "2020-01-30 00:00:00",
  },
  {
    id: 112,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "14.47",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "12.66",
      },
    ],
    client_id: 444,
    agreed: 1,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/129x100.png/dddddd/000000",
    },
    file_two: {
      name: "Privacy",
      original_name: "test file2.txt",
      url: "http://dummyimage.com/129x100.png/dddddd/000000",
    },
  },
  {
    id: 113,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "13.06",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "12.56",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/194x100.png/ff4444/ffffff",
    },
  },
  {
    id: 114,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "11.52",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "10.19",
      },
    ],
    client_id: 444,
    agreed: 1,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/109x100.png/cc0000/ffffff",
    },
  },
  {
    id: 115,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "19.63",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "17.13",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/240x100.png/5fa2dd/ffffff",
    },
  },
  {
    id: 116,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "18.30",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "15.17",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/220x100.png/cc0000/ffffff",
    },
  },
  {
    id: 117,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "13.85",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "13.40",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/128x100.png/ff4444/ffffff",
    },
  },
  {
    id: 118,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "12.82",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "11.39",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/179x100.png/dddddd/000000",
    },
  },
  {
    id: 119,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "11.45",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "12.20",
      },
    ],
    client_id: 444,
    agreed: 1,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/166x100.png/ff4444/ffffff",
    },
  },
  {
    id: 120,
    title: "Dev Rate Card Title",
    contact_name: "Adam Smith",
    first_name: "Adam",
    last_name: "Smith",
    email: "adam.smith@example.com",
    job_title: "Administrator",
    approval_override: false,
    rates: [
      {
        id: 1,
        rate_card_id: 111,
        time_start: "08:00",
        time_end: "20:00",
        job_title: "Mapa Support Worker",
        pay_rate: "14.74",
        shift_code: "MON – FRI (20:00-08:00)",
        charge_rate: "18.88",
      },
    ],
    client_id: 444,
    agreed: 0,
    created_at: new Date(2020, 10, 20).toISOString(),
    file_one: {
      name: "Terms",
      original_name: "test file.txt",
      url: "http://dummyimage.com/234x100.png/5fa2dd/ffffff",
    },
  },
];
