import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "focus-visible/dist/focus-visible";

import { AuthProvider } from "./lib/hooks/useAuth";
import Theme from "./theme";
import "./lib/api";
import App from "./App";

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Theme>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Theme>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
