import { rest, RestRequest, RestContext } from "msw";

import { staff, staff2 } from "../data/staff";
import { FormErrorResponse } from "lib/types";
import {
  IStaffTableResponse,
  IApiStaffInfoResponse,
  IStaffReport,
} from "lib/types/staff";

const getAllClients = rest.post(
  "/staff/all",
  async (_, res, ctx: RestContext) => {
    return res(
      ctx.status(200),
      ctx.json(staff.filter((st) => st.status === "active"))
    );
  }
);

const getStaffPaginated = rest.post(
  "/staff/table",
  async (req: RestRequest, res, ctx: RestContext) => {
    const page = req.url.searchParams.get("page") || "1";
    if (page === "1") {
      const response: IStaffTableResponse = {
        data: staff,
        current_page: 1,
        total: 30,
        first_page_url: "/staff/table",
        from: 1,
        last_page: 2,
        last_page_url: "/staff/table?page=2",
        next_page_url: "/staff/table?page=2",
        per_page: 15,
        to: 2,
      };
      return res(ctx.status(200), ctx.json(response));
    }
    const response: IStaffTableResponse = {
      data: staff2,
      current_page: 2,
      total: 30,
      first_page_url: "/staff/table",
      prev_page_url: "/staff/table",
      from: 1,
      last_page: 2,
      last_page_url: "/staff/table?page=2",
      per_page: 15,
      to: 2,
    };
    return res(ctx.status(200), ctx.json(response));
  }
);
const getStaff = rest.get("/staff/:id", async (req, res, ctx) => {
  const { id } = req.params;
  const response: IApiStaffInfoResponse = {
    ...staff[0],
    id,
  };
  return res(ctx.status(200), ctx.json(response));
});

const postStaff = rest.post("/staff/create", async (req: any, res, ctx) => {
  const { email } = req.body;
  if (email === "duplicate@email.com") {
    const errorResponse: FormErrorResponse = {
      errors: { email: ["Duplicate email"] },
      message: "",
    };
    return res(ctx.status(422), ctx.json(errorResponse));
  }

  return res(
    ctx.status(200),

    ctx.json({
      id: 1,
    })
  );
});

const updateStaff = rest.post("/staff/:id/edit", async (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      data: "Staff successfully updated",
    })
  );
});

const deleteStaff = rest.post("/staff/delete/:staffId", async (_, res, ctx) => {
  return res(
    ctx.status(200),

    ctx.json({
      success: true,
    })
  );
});

const staffReport = rest.get("/staff/:staffId/reports", async (_, res, ctx) => {
  const report: IStaffReport = {
    data: {
      shifts_accepted: 20,
      shifts_cancelled: 5,
      shifts_last_minute: 10,
    },
  };
  return res(ctx.status(200), ctx.json(report));
});

export const staffHandlers = [
  getAllClients,
  getStaffPaginated,
  getStaff,
  postStaff,
  updateStaff,
  deleteStaff,
  staffReport,
];
