const styles = {
  global: {
    body: {
      bg: "#F9F9FA",
      color: "black",
    },
    a: {
      color: "primary.500",
      _hover: {
        textDecoration: "underline",
      },
    },
    select: {
      paddingBottom: "0 !important",
    },
    ".chakra-form__required-indicator": {
      fontSize: "18px",
      lineHeight: 0,
      color: "primary.500",
    },
    ".word-break": {
      msWordBreak: "break-all",
      wordBreak: ["break-all", "break-word"],
      msHyphens: "auto",
      MozHyphens: "auto",
      WebkitHyphens: "auto",
      hyphens: "auto",
    },
    "*::placeholder": {
      fontFamily: "Arial",
    },
    ".note-day-picker": {
      width: "250px",
      height: "42px",
      borderWidth: "1px",
      borderStartWidth: 0,
      borderEndRadius: "3px",
      borderColor: "black",
      paddingStart: "5px",
      paddingEnd: "20px",
      fontSize: "sm",
      "&.with-full-border": {
        borderStartWidth: "1px",
        borderStartRadius: "3px",
      },
      "&.full-width": {
        width: "100%",
      },
    },
    ".shift-request-day-picker": {
      width: "250px",
      height: "42px",
      borderWidth: "1px",
      borderRadius: "3px",
      borderColor: "black",
      paddingStart: "5px",
      paddingEnd: "20px",
      fontSize: "sm",
    },
    ".react-datepicker__input-container": {
      width: "fit-content",
    },
    ".react-datepicker__close-icon": {
      paddingEnd: "1px",
      bg: "transparent !important",
      _after: {
        textAlign: "center",
        bg: "transparent !important",
        color: "#1A202C !important",
        _hover: {
          bg: "#F6E1ED !important",
        },
      },
    },
  },
};

export default styles;
