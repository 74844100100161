const Stat = {
  parts: ["label"],

  baseStyle: {
    label: {
      color: "gray.600",
      fontWeight: 300,
      fontSize: "4xl",
    },
  },
};

export default Stat;
