import { rest } from "msw";

import { media } from "../data/media";

const getClientMediaAll = rest.get(
  "/clients/:clientId/media",
  async (_, res, ctx) => {
    const response = media.slice(0, 5);
    return res(ctx.status(200), ctx.json(response));
  }
);
const getClientMediaById = rest.get(
  "/clients/:clientId/media/:mediaId",
  async (_, res, ctx) => {
    const response = media[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const clientPostMedia = rest.post(
  "/clients/:clientId/media/create",
  async (req, res, ctx) => {
    const response = media[0];

    return res(ctx.status(200), ctx.json(response));
  }
);
const clientEditMedia = rest.post(
  "/clients/:clientId/media/edit",
  async (req, res, ctx) => {
    const response = media[0];

    return res(ctx.status(200), ctx.json(response));
  }
);
const clientDeleteMedia = rest.post(
  "/clients/delete_media/:mediaId",
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ succes: true }));
  }
);

const getStaffMediaAll = rest.get(
  "/staff/:staffId/media",
  async (_, res, ctx) => {
    const response = media.slice(6, 10);
    return res(ctx.status(200), ctx.json(response));
  }
);
const getStaffMediaById = rest.get(
  "/staff/:staffId/media/:mediaId",
  async (_, res, ctx) => {
    const response = media[1];
    return res(ctx.status(200), ctx.json(response));
  }
);
const staffPostMedia = rest.post(
  "/staff/:staffId/media/create",
  async (req, res, ctx) => {
    const response = media[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const staffEditMedia = rest.post(
  "/staff/:staffId/media/edit",
  async (req, res, ctx) => {
    const response = media[0];
    return res(ctx.status(200), ctx.json(response));
  }
);
const staffDeleteMedia = rest.post(
  "/staff/delete_media/:mediaId",
  async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ succes: true }));
  }
);

export const mediaHandlers = [
  getClientMediaAll,
  getClientMediaById,
  clientPostMedia,
  clientEditMedia,
  clientDeleteMedia,
  getStaffMediaAll,
  getStaffMediaById,
  staffPostMedia,
  staffEditMedia,
  staffDeleteMedia,
];
