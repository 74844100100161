import { IApiStaffInfoResponse } from "lib/types/staff";

export const staff: IApiStaffInfoResponse[] = [
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    status: "pre-reg",
    first_name: "Annie",
    last_name: "Calvard",
    preferred_name: "Test name",
    visa_info: "visa",
    email: "acalvard0@devhub.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 112,
    reference_no: "SH00112",
    rating: 4.5,
    form_id: "",
    status: "active",
    first_name: "Gypsy",
    last_name: "Mansford",
    preferred_name: "Test name",
    visa_info: "visa",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Gregg",
    last_name: "Tomanek",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Lefty",
    last_name: "Tremoille",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Georgena",
    last_name: "Bellord",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Jo",
    last_name: "Tamplin",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Tina",
    last_name: "Benyon",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Mose",
    last_name: "Favela",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Debee",
    last_name: "Regi",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Cyril",
    last_name: "Matfield",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Patton",
    last_name: "Halbard",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Hymie",
    last_name: "Bloomfield",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Stacy",
    last_name: "Vardy",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Vin",
    last_name: "Cocozza",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Una",
    last_name: "Monte",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Cassius",
    last_name: "Hayen",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Cyrill",
    last_name: "Langcastle",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Georgie",
    last_name: "Dalla",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Garvin",
    last_name: "Bichener",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Ilaire",
    last_name: "Chapiro",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
];

export const staff2: IApiStaffInfoResponse[] = [
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Valerye",
    last_name: "Hulle",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Caye",
    last_name: "Twinning",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Antony",
    last_name: "Sarfatti",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Buddy",
    last_name: "Coulthard",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Robinette",
    last_name: "Caltun",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Anett",
    last_name: "Schrieves",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Gretta",
    last_name: "Jenyns",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Brooks",
    last_name: "Huertas",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Andreana",
    last_name: "Palphreyman",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Dannie",
    last_name: "Ravenscroft",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Farr",
    last_name: "Huncote",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Whitby",
    last_name: "Messitt",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Corabella",
    last_name: "Eddolls",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Gilbertina",
    last_name: "Roxburgh",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Viviana",
    last_name: "Abrami",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Kris",
    last_name: "Rudgerd",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Ariel",
    last_name: "Husset",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "active",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Mahalia",
    last_name: "Bridell",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pre-reg",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Dori",
    last_name: "Isselee",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "pending",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
  {
    id: 111,
    reference_no: "SH00111",
    rating: 4.5,
    form_id: "",
    first_name: "Tarra",
    last_name: "Roloff",
    preferred_name: "Test name",
    visa_info: "visa",
    status: "do not use",
    email: "gm@example.com",
    home_number: "445-355-7396",
    mobile_number: "313-829-2675",
    minimum_6_month_education: "yes",
    service_user_experience: "Elderly Care",
    preferred_travel_distance: "40",
    post_code: "string",
    shift_capacity_per_week: "5",
    student_visa: "yes",
    driver: "yes",
    reason_for_joining_agency: "string",
    candidate_source: "Indeed",
    safeguarding_concerns: "string",
    dbs_or_updated_service: "adults",
    do_not_email: true,
    proof_of_double_vaccination: false,
    any_convictions: false,
    location: "string",
    ni_number: "string",
    nmc_number: "string",
    user_type: 4,
    title: "Job Title",
    consultant_id: 1,
    shift_type: 1,
    profile_stats: {
      first_shift_completed: "asdas",
      last_places_worked: ["dsadsdsa"],
      most_recent_shift: "dsadasdasdas",
    },
  },
];
