const Table = {
  parts: ["th"],
  baseStyle: {
    th: {
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "xs",
      letterSpacing: "-0.2px",
      // && is a high specificity selector hack so we can override color and borderColor
      // https://github.com/chakra-ui/chakra-ui/issues/4122#issuecomment-852399070
      "&&": { color: "black", borderColor: "gray.300", pl: 2, pr: 2 },
    },
    tbody: {
      th: {
        borderRightWidth: 1,
      },
    },
    td: {
      fontWeight: 600,
      letterSpacing: "-0.23px",
      fontSize: "sm",
      "&&": { borderColor: "gray.300", pl: 2, pr: 2 },
    },
  },
};

export default Table;
