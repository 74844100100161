import { rest } from "msw";

import { IDashStatsResponse } from "lib/types";

const dashboardStats = rest.get(
  "/dashboard/stats",
  async (_: any, res, ctx) => {
    const response: IDashStatsResponse = {
      data: {
        number_of_hours: { label: "No. of Hours", value: 13 },
        shifts_booked: { label: "Shifts Booked", value: 13 },
        homes_supplied: { label: "Homes Supplied", value: 13 },
        new_registrations: { label: "New Registrations", value: 13 },
        candidates_placed: { label: "Candidates Placed", value: 13 },
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

const dashboardAvailability = rest.post(
  "/dashboard/availability",
  async (_: any, res, ctx) => {
    const response: { data: { [k: string]: string[] } } = {
      data: {
        "2022-06-04": ["StaffName1"],
        "2022-06-01": ["StaffName2"],
        "2022-05-31": ["StaffName3"],
        "2022-05-30": ["StaffName1"],
        "2022-06-02": ["StaffName2"],
        "2022-06-03": ["StaffName3"],
        "2022-06-05": ["StaffName1"],
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }
);

export const dashboardHandlers = [dashboardStats, dashboardAvailability];
